html,
body,
#root {
  height: 100%;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 14px;
  overflow-x: hidden;
  max-width: 100%;
}

:root {
  --accent: #1e90ff;
  --background: #ffffff;
  --primary: rgb(30, 30, 30);
  --secondary: rgb(100, 100, 100);
  --tertiary: rgb(210, 210, 210);
  --danger: orange;
}

div {
  box-sizing: border-box;
  display: flex;
}
a {
  color: var(--primary);
  font-weight: bold;
  transition: 0.5s ease;
}

a:hover {
  color: var(--accent);
}

.main-container {
  align-items: center;
  justify-content: center;
  flex: 1;
  overflow-x: hidden;
  max-width: 100%;
}

.middle-container {
  background-color: var(--background);
  padding-top: 20px;
  align-items: center;
  width: 250px;
  max-width: 100%;
  height: 100%;
  flex-direction: column;
}
.calendar-controls {
  width: 100%;
  justify-content: space-between;
  padding: 5px 10px;
  align-items: center;
}

.calendar-controls svg {
  cursor: pointer;
  padding-left: 10px;
  color: var(--accent);
}

.calendar-widget {
  width: 100%;
}

.calendar-table {
  flex-direction: column;
  width: 100%;
  height: fit-content;
  border: 1px solid var(--tertiary);
  padding: 3px;
}

.calendar-row {
  width: 100%;
  justify-content: space-evenly;
}

.calendar-cell {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 3px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 12px;
  background-color: var(--background);
}

.calendar-cell:hover,
.calendar-cell.selected {
  background-color: var(--accent);
  color: var(--background);
  transition: 0.6s ease;
}

.calendar-cell.disabled {
  color: var(--secondary);
}
.diff-month {
  pointer-events: none;
  opacity: 0;
}

.disabled {
  cursor: default;
  pointer-events: none;
}

form,
.booking-widget {
  width: 100%;
  position: relative;
}

form > div {
  transition: 0.7s ease;
  position: absolute;
  width: 100%;
}

button,
.input-container {
  width: 100%;
  min-width: 100%;
  box-sizing: border-box;
  height: 40px;
  border: 1px solid var(--tertiary);
  font-family: inherit;
  font-size: 14px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}

button {
  border: none;
  background-color: var(--accent);
  color: var(--background);
}

.input-container {
  position: relative;
  margin: 3px 0;
}

.input-container svg,
.covers-picker .option svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 5px;
  color: var(--accent);
  width: 26px !important;
  height: auto !important;
}
.am-pm .row .cell,
.please-move .row .cell {
  margin: 0 1.5px;
}
.cell {
  height: 30px;
  border: 1px solid var(--tertiary);
  justify-content: center;
  align-items: center;
  font-size: 12px;
  width: calc(calc(100% / 4) - 2px);
  cursor: pointer;
}
.am-pm .row,
.please-move .row {
  justify-content: center;
}
.row {
  flex: 1;
  margin-bottom: 3px;
  justify-content: space-between;
  position: relative;
}

.time-picker,
.hours,
.no-availability,
.please-move,
.details,
.confirmation {
  flex-direction: column;
}
.cell.selected,
.cell:hover {
  border: 0;
  background-color: var(--accent);
  color: var(--background);
}
hr {
  opacity: 0;
  height: 1px;
  width: 100%;
  background-color: var(--tertiary);
  border: 0;
  padding: 0;
  margin: 10px 0 12px 0;
}
.covers-picker,
.search-form,
.calendar {
  flex-direction: column;
}
.covers-picker .option {
  width: 100%;
  margin-bottom: 3px;
  height: 30px;
  border: 1px solid var(--tertiary);
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 13px;
  cursor: pointer;
}
p {
  font-size: 13px;
  text-align: center;
  margin: 0;
}

.covers-picker .option.selected {
  border: 0;
  color: var(--background);
  background: var(--accent);
}
.covers-picker .option.selected svg {
  color: var(--background) !important;
}
.done {
  width: 50%;
  align-self: center;
  font-size: 13px;
  background-color: var(--accent);
  color: var(--background);
  height: 30px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.no-availability p,
.confirmation p {
  margin: 10px;
}

.no-availability {
  flex-direction: column;
}

.link-text {
  width: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--accent);
}

.link-text:hover {
  color: var(--accent);
}

input.input-container,
textarea.input-container {
  outline: none;
  padding-left: 15px;
  margin-top: 10px;
  cursor: unset;
  background-color: rgba(255, 255, 255, 0.8);
}
textarea.input-container {
  padding: 15px;
  min-height: 10rem;
  height: 10rem;
}

.warning {
  color: var(--danger);
  padding: 12px;
  border: 1px solid var(--danger);
  font-weight: bold;
  text-align: center;
  font-size: 12px;
}

@media screen and (max-width: 550px) and (min-width: 350px) {
  .booking-widget {
    transform: scale(1.3);
    transform-origin: top;
  }
}
.cancel-page {
  display: flex;
  flex-direction: column;
  margin: 50px auto;
  justify-items: center;
  align-items: center;
}
.button:hover {
  filter: brightness(1.2);
}

.button {
  background: rgb(135, 96, 55);
  padding: 10px 20px;
  margin: 10px;
  color: white;
  cursor: pointer;
  justify-content: center;
}
.buttons {
  display: flex;
  flex-direction: column;
  position: relative;
}
.confirmed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #8b633b;
  pointer-events: all;
  transition: 0.7s ease;
  color: white;
  font-size: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.confirmed.hidden {
  pointer-events: none;
  opacity: 0;
}
